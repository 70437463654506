import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader } from '../../../../../_metronic/_partials/controls';
import { ProductionsListTable } from './components/ProductionsListTable';
import * as orgActions from './../../../UserInfo/_redux/myOrganisations/myOrganisationsActions';
import * as objActions from './../../../UserInfo/_redux/myObjects/myObjectsActions';
import { ProductionsListFilter } from './components/ProductionsListFilter';

export const ProductionsListCard = () => {
  const dispatch = useDispatch();

  const { currentState } = useSelector(state => ({ currentState: state.productions }));
  const { ProductionsListEntities, ProductionsListTotalCount, ProductionsListLoading } = currentState;

  useEffect(() => {
    dispatch(objActions.getAllMyObjects());
    dispatch(orgActions.getAllMyOrganisations());
  }, []);

  return (
    <Card>
      <CardHeader title="Реестр изделий" />
      <CardBody>
        <ProductionsListFilter />
        <ProductionsListTable entities={ProductionsListEntities} totalCount={ProductionsListTotalCount} loading={ProductionsListLoading} />
      </CardBody>
      <CardFooter />
    </Card>
  );
};
