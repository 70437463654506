import React, { createContext, useContext, useState, useCallback } from 'react';
import { isEqual, isFunction } from 'lodash';

import { initialFilter } from './ProductionsListUIHelpers';

const ProductionsListUIContext = createContext();

export function useProductionsListUIContext() {
  return useContext(ProductionsListUIContext);
}

export const ProductionsListUIConsumer = ProductionsListUIContext.Consumer;

export const ProductionsListUIProvider = ({ children }) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
  };

  return <ProductionsListUIContext.Provider value={value}>{children}</ProductionsListUIContext.Provider>;
};
