import { all } from 'redux-saga/effects';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { customersSlice } from '../app/modules/ECommerce/_redux/customers/customersSlice';
import { productsSlice } from '../app/modules/ECommerce/_redux/products/productsSlice';
import { remarksSlice } from '../app/modules/ECommerce/_redux/remarks/remarksSlice';
import { specificationsSlice } from '../app/modules/ECommerce/_redux/specifications/specificationsSlice';
import { calculationsSlice } from '../app/modules/ECommerce/_redux/calculations/calculationsSlice';
import { calculationSlice } from '../app/modules/ECommerce/_redux/calculation/calculationSlice';
import { notificationsSlice } from '../app/modules/Notifications/_redux/notificationsSlice';
import { unreadNotifiSlice } from '../app/widgets/unreadNotifi/_redux/unreadNotifiSlice';
import { ordersSlice } from '../app/modules/ECommerce/_redux/orders/ordersSlice';
import { myObjectsSlice } from '../app/modules/UserInfo/_redux/myObjects/myObjectsSlice';
import { calculationTOSlice } from '../app/modules/ECommerce/_redux/calculationTO/calculationTOSlice';
import { myOrganisationsSlice } from '../app/modules/UserInfo/_redux/myOrganisations/myOrganisationsSlice';
import { calculationTO2Slice } from '../app/modules/ECommerce/_redux/calculationTO2/calculationTO2Slice';
import { calculationTO3Slice } from '../app/modules/ECommerce/_redux/calculationTO3/calculationTO3Slice';
import { calculationPumpSlice } from '../app/modules/ECommerce/_redux/calculationPump/calculationPumpSlice';
import { homeSlice } from '../app/modules/Home/_redux/homeSlice';
import { orderSlice } from '../app/modules/ECommerce/_redux/order/orderSlice';
import { authSlice } from '../app/modules/Auth/_redux/authSlice';
import { chatsSlice } from '../app/modules/ECommerce/_redux/chats/chatsSlice';
import { reportsSlice } from '../app/modules/Support/redux/reportsSlice';
import { scannerSlice } from '../app/modules/Tools/redux/scannerr/scannerSlice';
import { calculationPumpStationSlice } from '../app/modules/ECommerce/_redux/calculationPumpStation/calculationPumpStationSlice';
import { calculationControlSlice } from '../app/modules/ECommerce/_redux/calculationControl/calculationControlSlice';
import { promotionsSlice } from '../app/modules/Promotions/redux/promotionsSlice';
import { promotionSlice } from '../app/modules/Promotion/redux/promotionSlice';
import { calculationBTPSlice } from '../app/modules/ECommerce/_redux/calculationBTP/calculationBTPSlice';
import { scadaSlice } from '../app/modules/Tools/redux/scada/ScadaSlice';
import { ProductionsSlice } from '../app/modules/Productions/redux/ProductionsSlice';
import { calculationFlowMeterSlice } from '../app/modules/ECommerce/_redux/calculationFlowMeter/calculationFlowMeterSlice';

export const rootReducer = combineReducers({
  auth: persistReducer(
    {
      storage,
      key: 'atri-lk-auth',
      whitelist: ['authToken', 'showStartInfoTooltip', 'ymetrika'],
    },
    authSlice.reducer,
  ),
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  calculations: calculationsSlice.reducer,
  calculation: calculationSlice.reducer,
  calculationTO: calculationTOSlice.reducer,
  calculationTO2: calculationTO2Slice.reducer,
  calculationTO3: calculationTO3Slice.reducer,
  notifications: notificationsSlice.reducer,
  unreadNotifi: unreadNotifiSlice.reducer,
  orders: ordersSlice.reducer,
  order: orderSlice.reducer,
  myObjects: myObjectsSlice.reducer,
  myOrganisations: myOrganisationsSlice.reducer,
  calculationPump: calculationPumpSlice.reducer,
  home: homeSlice.reducer,
  chats: chatsSlice.reducer,
  reports: reportsSlice.reducer,
  scanner: scannerSlice.reducer,
  calculationPumpStation: calculationPumpStationSlice.reducer,
  calculationControl: calculationControlSlice.reducer,
  promotions: promotionsSlice.reducer,
  promotion: promotionSlice.reducer,
  calculationBTP: calculationBTPSlice.reducer,
  scada: scadaSlice.reducer,
  productions: ProductionsSlice.reducer,
  calculationFlowMeter: calculationFlowMeterSlice.reducer,
});

export function* rootSaga() {
  yield all([]);
}
