import * as requestFromServer from './ProductionsCrud';
import { ProductionsSlice, callTypes } from './ProductionsSlice';

const { actions } = ProductionsSlice;

export const getProductionsList = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getProductionsList }));
  return requestFromServer
    .getProductionsList(queryParams)
    .then(response => {
      const entities = response.data;
      const totalCount = response.meta.pagination.total;
      dispatch(actions.allProductionsListFetched({ entities, totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't get all dispatching objects!";
      dispatch(actions.catchError({ error, callType: callTypes.getProductionsList }));
    });
};

export const getProductionsShow = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.getProductionsShow }));
  return requestFromServer
    .getProductionsShow(queryParams)
    .then(response => {
      const { entity } = { entity: response.data };
      dispatch(actions.ProductionsShowFetched({ entity }));
    })
    .catch(error => {
      error.clientMessage = "Can't get dispatching object!";
      dispatch(actions.catchError({ error, callType: callTypes.getProductionsShow }));
    });
};

export const fetchProductionsDocuments = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.findProductionsDocuments }));
  return requestFromServer
    .findProductionsDocuments(queryParams)
    .then(response => {
      dispatch(actions.docFetched(response));
    })
    .catch(error => {
      error.clientMessage = "Can't get order documents!";
      dispatch(actions.catchError({ error, callType: callTypes.findProductionsDocuments }));
    });
};
