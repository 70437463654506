import React, { useEffect, useCallback, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { useHistory } from 'react-router';

import { Card, CardBody, CardFooter, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { CardSpinner } from '../../../../widgets/cardSpinner/CardSpinner';
import * as actions from './../../redux/ProductionsActions';

import { ProductionsDocumentsDialog } from './components/ProductionsDocumentsDialog';
import { ProductionsModalFiles } from './components/ProductionsModalFiles';

const TAB_NAMES = {
  project: 0,
  variables: 1,
  trends: 2,
  reports: 3,
};

export const ProductionsShowCard = ({ id }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedTab, setSelectedTab] = useState(TAB_NAMES.project);

  const { currentState } = useSelector(state => ({ currentState: state.productions }));
  const history = useHistory();
  const { ProductionsShowEntity, ProductionsShowLoading } = currentState;

  const dispatch = useDispatch();
  const [isDocShow, setIsDocShow] = useState(false);
  const [showModalFiles, setShowModalPumpInfo] = useState(false);

  useEffect(() => {
    dispatch(actions.getProductionsShow({ id: id }));
  }, []);

  const goToMyRequestPage = useCallback(() => {
    history.push(`../e-commerce/design/calculations/${ProductionsShowEntity.request_id}/calculation`);
  }, [history, ProductionsShowEntity, dispatch]);

  const goToMyOrderPage = useCallback(() => {
    history.push(`../e-commerce/orders/${ProductionsShowEntity.sales_id}/order`);
  }, [history, ProductionsShowEntity, dispatch]);

  return (
    <>
      {ProductionsShowEntity === null || ProductionsShowLoading ? (
        <div className="col-12">
          <div className="card card-custom card-stretch gutter-b">
            <div className="card-body">
              <CardSpinner />
            </div>
          </div>
        </div>
      ) : (
        <>
          {showModalFiles && <ProductionsModalFiles showModal={showModalFiles} setShowModal={setShowModalPumpInfo} />}
          <ProductionsDocumentsDialog show={isDocShow} onHide={() => setIsDocShow(false)} production_id={id} />
          <div className="row">
            <div className="col-12 col-xxl-7">
              <Card className="card-stretch">
                <CardHeader title={`${ProductionsShowEntity.title ? ProductionsShowEntity.title : ''}`} />
                <CardBody>
                  <div className="row mb-17">
                    <div className="col-xxl-5 mb-11 mb-xxl-0">
                      <div className="  px-10 py-15 d-flex align-items-center justify-content-center">
                        <img
                          src={ProductionsShowEntity.preview}
                          alt="Изображение товара"
                          className="mw-100 w-200px"
                          style={{ transform: 'scale(1.6)' }}
                        />
                      </div>
                    </div>
                    <div className="col-xxl-7 pl-xxl-11">
                      <h2 className="font-weight-bolder text-dark">
                        Серийный номер: {ProductionsShowEntity.serial ? ProductionsShowEntity.serial : ''}
                      </h2>
                      <h4 className="font-weight-bolder text-dark mb-7">
                        Артикул: {ProductionsShowEntity.sku ? ProductionsShowEntity.sku : ''}
                      </h4>
                      <div className="line-height-xl text-dark-50">
                        {ProductionsShowEntity.description ? ProductionsShowEntity.description : ''}
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    <div className="col-12">
                      <div className="font-weight-bolder text-dark mb-3"></div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className=" d-flex flex-column">
                        <span className="text-dark font-weight-bold mb-4">Организация</span>
                        <span className="text-muted font-weight-bolder font-size-lg">
                          {ProductionsShowEntity.oid_name ? ProductionsShowEntity.oid_name : ''}
                        </span>
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className=" d-flex flex-column">
                        <span className="text-dark font-weight-bold mb-4">Объект</span>
                        <span className="text-muted font-weight-bolder font-size-lg">
                          {ProductionsShowEntity.object_name ? ProductionsShowEntity.object_name : ''}
                        </span>
                      </div>
                    </div>
                    {ProductionsShowEntity.has_request && (
                      <div className="col-6 col-md-3">
                        <div className=" d-flex flex-column">
                          <span className="text-dark font-weight-bold mb-4">Номер расчета</span>
                          <span className="text-muted font-weight-bolder font-size-lg">
                            №{ProductionsShowEntity.request_id}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bolder mr-6 px-6 font-size-sm"
                      onClick={() => setShowModalPumpInfo(true)}
                    >
                      Документация
                    </button>
                    {ProductionsShowEntity.has_request && (
                      <button
                        type="button"
                        className="btn btn-light-primary font-weight-bolder mr-6 px-8 font-size-sm"
                        onClick={() => goToMyRequestPage()}
                      >
                        Перейти в расчет
                      </button>
                    )}
                    {ProductionsShowEntity.has_realization && (
                      <>
                        <button
                          type="button"
                          className="btn btn-light-primary font-weight-bolder mr-6 px-8 font-size-sm"
                          onClick={() => goToMyOrderPage()}
                        >
                          Перейти в заказ
                        </button>

                        <button
                          className="btn btn-sm btn-light-success font-weight-bolder font-size-sm mr-3"
                          onClick={() => setIsDocShow(true)}
                        >
                          Отгрузочные документы
                        </button>
                      </>
                    )}
                  </div>
                </CardFooter>
              </Card>
            </div>
            <div className="col-12 col-xxl-5">
              <Card className="card-stretch">
                <CardHeader title="Статус производства" />
                <CardBody className="pt-0">
                  <div class="timeline timeline-6 mt-3">
                    {ProductionsShowEntity.has_history ? (
                      <>
                        {ProductionsShowEntity.history.map(change => (
                          <div className="timeline-item align-items-start">
                            <div title={change.date_full} className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                              {change.date}
                            </div>

                            <div className="timeline-badge">
                              <i className={`fa fa-genderless ${change.status === 1 ? 'text-success' : ''} icon-xl`}></i>
                            </div>

                            <div className="timeline-content d-flex">
                              <div className="row">
                                <div className="col-12">
                                  <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">
                                    {change.title}
                                  </span>
                                </div>
                                <div className="col-12">
                                  <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
                                    {change.description}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : 'К сожалению истории движения по данному товару не найдена'}
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </>
      )}
    </>
  );
};
