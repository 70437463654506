import React from 'react';
import { ProductionsTypeCssClasses, ProductionsStatus } from '../../ProductionsListUIHelpers';

export function StatusColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${ProductionsTypeCssClasses[row.status]} label-inline`;
  };

  return <span className={getLabelCssClasses()}>{ProductionsStatus[cellContent]}</span>;
}
