import React, { useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SVG from 'react-inlinesvg';

export const ProductionsModalFiles = ({ showModal, setShowModal }) => {
  const { currentState } = useSelector(state => ({ currentState: state.productions }));
  const { ProductionsShowEntity } = currentState;

  const files_view = useMemo(() => {
    return ProductionsShowEntity.files.length === 0 ? (
      <span>Документы отсутствуют...</span>
    ) : (
      ProductionsShowEntity.files.map(doc => (
        <>
          <div className="d-flex flex-column rounded mt-2 mb-3 px-5 py-3 bg-light-primary text-dark-50 font-weight-bold font-size-lg max-w-600px">
            <a
              href={doc.file}
              target="_blank"
              rel="noopener noreferrer"
              className="my-3 svg-icon svg-icon-xxl svg-icon-success"
            >
              <SVG src={'/media/svg/icons/Files/File.svg'} />
              {doc.name}
            </a>
          </div>
        </>
      ))
    );
  }, [ProductionsShowEntity.files]);
  return (
    <Modal show={showModal} animation={true}>
      <Modal.Header>
        <Modal.Title>Документы</Modal.Title>
      </Modal.Header>
      <Modal.Body> {files_view}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowModal(false)} variant="primary">
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
