export const defaultSorted = [{ dataField: 'id', order: 'desc' }];
export const sizePerPageList = [
  { text: '3', value: 3 },
  { text: '5', value: 5 },
  { text: '10', value: 10 },
];

export const initialFilter = {
  filter: {
    date: '',
    status: '',
    title: '',
  },
  sortOrder: 'desc', // asc||desc
  sortField: 'id',
  pageNumber: 1,
  pageSize: 10,
  searchText: '',
};

export const STATUS = {
  NO_CONNECT: 'В производстве',
  ONLINE: 'Готово',
};

export const ProductionsStatus = [STATUS.NO_CONNECT, STATUS.ONLINE];

export const ProductionsTypeCssClasses = ['warning', 'success'];
