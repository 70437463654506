import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { LayoutSplashScreen } from '../_metronic/layout';
import { HomePage } from './modules/Home/HomePage';
import { NotificationsPage } from './modules/Notifications/NotificationsPage';
import { UserInfoPage } from './modules/UserInfo/UserInfoPage';
import { ROUTES } from './Routes.models';
import { ReportsPage } from './modules/Support/ReportsPage';
import { PromotionsPage } from './modules/Promotions/PromotionsPage';
import { PromotionPage } from './modules/Promotion/PromotionPage';
import { ScannerPage } from './modules/Tools/pages/scanner/ScannerPage';
import { ScadaListPage } from './modules/Tools/pages/scada/list/ScadaListPage';
import { ScadaShowPage } from './modules/Tools/pages/scada/show/ScadaShowPage';
import { ProductionsListPage } from './modules/Productions/pages/list/ProductionsListPage';
import { ProductionsShowPage } from './modules/Productions/pages/show/ProductionsShowPage';
const ECommercePage = lazy(() => import('./modules/ECommerce/pages/eCommercePage'));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to={ROUTES.HOME} />
        {/* <ContentRoute path="/dashboard" component={DashboardPage} /> */}
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route exact path={ROUTES.PROMOTIONS} component={PromotionsPage} />
        <Route exact path={ROUTES.PROMOTION}>
          {({ match }) => <PromotionPage id={match && match.params.id} match={match} />}
        </Route>
        <Route path={ROUTES.NOTIFI} component={NotificationsPage} />
        <Route path={ROUTES.E_COMMERCE} component={ECommercePage} />
        <Route path={ROUTES.USER} component={UserInfoPage} />
        <Route path={ROUTES.SUPPORT_REPORTS} component={ReportsPage} />
        <Route path={ROUTES.TOOLS_SCANNER} component={ScannerPage} />
        <Route exact path={ROUTES.TOOLS_SCADA_LIST} component={ScadaListPage} />
        <Route path={ROUTES.TOOLS_SCADA_SHOW}>
          {({ match }) => <ScadaShowPage id={match && match.params.id} match={match} />}
        </Route>
        <Route exact path={ROUTES.PRODUCTIONS} component={ProductionsListPage} />
        <Route path={ROUTES.PRODUCTION}>
          {({ match }) => <ProductionsShowPage id={match && match.params.id} match={match} />}
        </Route>
        <Redirect to={ROUTES.ERROR} />
      </Switch>
    </Suspense>
  );
}
