import React, { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { Modal } from 'react-bootstrap';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../redux/ProductionsActions';
import { ModalProgressBar } from '../../../../../../_metronic/_partials/controls';

export const ProductionsDocumentsDialog = ({ show, onHide, production_id }) => {
  const dispatch = useDispatch();

  const { currentState } = useSelector(state => ({ currentState: state.productions }));

  const { orderDocuments, docLoading } = currentState;

  useEffect(() => {
    if (show) {
      dispatch(actions.fetchProductionsDocuments({ id: production_id }));
    }
  }, [dispatch, production_id, show]);

  const docs = useMemo(() => {
    return orderDocuments.length === 0 ? (
      <span>Документы отсутствуют...</span>
    ) : (
      orderDocuments.map(doc => (
        <>
          <span>{`${doc.numb}, создан: ${doc.date}`}</span>
          <div className="d-flex flex-column rounded mt-2 mb-3 px-5 py-3 bg-light-primary text-dark-50 font-weight-bold font-size-lg max-w-600px">
            {doc.attach.map(file => (
              <a
                key={file.id}
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                className="my-3 svg-icon svg-icon-xxl svg-icon-success"
              >
                <SVG src={'/media/svg/icons/Files/File.svg'} />
                {file.name}
              </a>
            ))}
          </div>
        </>
      ))
    );
  }, [orderDocuments]);

  return (
    <Modal show={show} onHide={onHide}>
      {docLoading ? <ModalProgressBar /> : ''}
      <Modal.Header closeButton>
        <Modal.Title id="">Отгрузочные документы</Modal.Title>
      </Modal.Header>
      <Modal.Body>{docLoading ? <p>Загрузка...</p> : docs}</Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-primary btn-elevate">
            Закрыть
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
