import { createSlice } from '@reduxjs/toolkit';

const initialProductionsState = {
  ProductionsListLoading: false,
  ProductionsListTotalCount: 0,
  ProductionsListEntities: [],

  ProductionsShowLoading: false,
  docLoading: false,
  ProductionsShowVarLoading: false,
  ProductionsShowEntity: {},
  ProductionsShowVarEntities: [],
  orderDocuments: [],
  error: null,
};
export const callTypes = {
  getProductionsList: 'getProductionsList',
  getProductionsShow: 'getProductionsShow',
  getProductionsShowVar: 'getProductionsShowVar',
  findProductionsDocuments: 'findProductionsDocuments',
};

export const ProductionsSlice = createSlice({
  name: 'Productions',
  initialState: initialProductionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.getProductionsList) {
        state.ProductionsListLoading = false;
      }

      if (action.payload.callType === callTypes.getProductionsShow) {
        state.ProductionsShowLoading = false;
      }

      if (action.payload.callType === callTypes.getProductionsShowVar) {
        state.ProductionsShowVarLoading = false;
      }

      if (action.payload.callType === callTypes.findProductionsDocuments) {
        state.docLoading = false;
        return;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.getProductionsList) {
        state.ProductionsListLoading = true;
      }

      if (action.payload.callType === callTypes.getProductionsShow) {
        state.ProductionsShowLoading = true;
      }

      if (action.payload.callType === callTypes.getProductionsShowVar) {
        state.ProductionsShowVarLoading = true;
      }

      if (action.payload.callType === callTypes.findProductionsDocuments) {
        state.docLoading = true;
        return;
      }
    },

    allProductionsListFetched: (state, action) => {
      const { entities, totalCount } = action.payload;

      state.ProductionsListLoading = false;
      state.error = null;
      state.ProductionsListEntities = entities;
      state.ProductionsListTotalCount = totalCount;
      state.error = null;
    },

    ProductionsShowFetched: (state, action) => {
      const { entity } = action.payload;
      state.ProductionsShowLoading = false;
      state.ProductionsShowEntity = entity;
      state.error = null;
    },

    ProductionsShowVarFetched: (state, action) => {
      const { entities } = action.payload;
      state.ProductionsShowVarLoading = false;
      state.ProductionsShowVarEntities = entities;
      state.error = null;
    },

    docFetched: (state, action) => {
      state.docLoading = false;
      state.orderDocuments = action.payload;
    },
  },
});
