import React from 'react';
import { ProductionsListCard } from './ProductionsListCard';
import { ProductionsListUIProvider } from './ProductionsListUIContext';

export const ProductionsListPage = () => {
  return (
    <ProductionsListUIProvider>
      <ProductionsListCard />
    </ProductionsListUIProvider>
  );
};