import { createSlice } from '@reduxjs/toolkit';

const initialControlState = {
  ControlListLoading: false,
  pumpCalculationSaving: false,
  entities: null,
  pumpResult: null,
  pumpFormValues: null,
  saveResponseType: null,
  attachId: null,
  saveCalculationNum: null,
};

export const callTypes = {
  list: 'list',
  saveCalc: 'saveCalc',
};

export const calculationControlSlice = createSlice({
  name: 'calculationControl',
  initialState: initialControlState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.ControlListLoading = false;
        return;
      }
      if (action.payload.callType === callTypes.saveCalc) {
        state.pumpCalculationSaving = false;
        return;
      }
    },

    startCall: (state, action) => {
      state.error = null;
      state.attachId = null;
      state.saveCalculationNum = null;
      state.saveResponseType = null;
      state.responseType = null;

      if (action.payload.callType === callTypes.list) {
        state.ControlListLoading = true;
        return;
      }

      if (action.payload.callType === callTypes.saveCalc) {
        state.pumpCalculationSaving = true;
        return;
      }
    },

    ControlCalculationFetched: (state, action) => {
      const { data, queryParams } = action.payload;
      state.ControlListLoading = false;
      state.error = null;
      state.entities = data.entities;
      state.type_use = data.type_use;
      state.entities_links = {};
      data.entities.forEach((item, index) => {
        state.entities_links[item.cid] = index;
      });
      state.pumpResult = data.type;
      state.pumpFormValues = queryParams;
    },

    saveControlCalculationFetched: (state, action) => {
      const { data } = action.payload;
      state.pumpCalculationSaving = false;
      state.error = null;
      if (data.type === 'success') {
        state.attachId = data.aid;
        state.saveResponseType = data.type;
        state.saveCalculationNum = data.request;
        return;
      }
    },

    clearSaveResponseType: state => {
      state.saveResponseType = initialControlState.saveResponseType;
      state.attachId = initialControlState.attachId;
      state.saveCalculationNum = initialControlState.saveCalculationNum;
    },
  },
});
